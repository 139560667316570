<template>
  <div id="visitor-entry">
    <div v-if="!journeyTitle" id="visitor-entry-loading">
      <progress class="progress is-small is-default" max="100">15%</progress>
    </div>
    <div>
      <div class="background" :style="backgroundStyle"></div>
      <div id="selected-language">
        <img
          :src="'/img/flags/' + this.language + '.png'"
           @click="moveToLanguageSelection"
          />
      </div>
      <div id="visitor-entry-content">
        <h1 v-if="journeyTitle">
          {{ journeyTitle }}
        </h1>
        <div id="get-permissions">
          <div class="notification is-warning" v-if="isFirefox">
            {{ $t("visitorEntry.firefoxWarning1") }}<br/>
            {{ $t("visitorEntry.firefoxWarning2") }}<br/>
          </div>
          <div class="notification is-warning" v-if="webview">
            <div id="webview-needs-redirection">
              <p v-html="$t('visitorEntry.webview1')"></p>
              <p v-html="$t('visitorEntry.webview2')"></p>
              <br/>
            </div>
          </div>
          <div class="notification is-default is-light">
            <div v-if="freshStart">
              {{ $t("visitorEntry.needsAuthorization1") }}
              <br/><br/>
              {{ $t("visitorEntry.needsAuthorization2") }}
            </div>
            <div v-else>
              {{ $t("visitorEntry.needsAuthorizationResume") }}
            </div>
          </div>
          <GetPermissions @permissions-ok="onPermissionsOk"></GetPermissions>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetPermissions from '@/components/GetPermissions'

import axiosInstance from '@/api'
import isWebview from 'is-ua-webview'

const isFirefox = /^(?!.*Seamonkey)(?=.*Firefox).*/i.test(navigator.userAgent)

export default {
  name: 'VisitorEntry',
  components: {
    GetPermissions
  },
  data () {
    return {
      journeyTitle: '',
      webview: false,
      isFirefox
    }
  },
  computed: {
    ticketUid () {
      return this.$route.params.ticketUid
    },
    language () {
      return this.$store.state.mainInfos.language
    },
    freshStart () {
      return this.$store.state.mainInfos.freshStart
    },
    backgroundStyle () {
      return {
        'background-image': 'url("' + this.$store.state.mainInfos.defaultBackground + '")'
      }
    }
  },
  async created () {
    // Checking if we are really in a browser or not
    if (isWebview(navigator.userAgent)) {
      this.webview = true
    } else {
      // Facebook app
      const ua = navigator.userAgent || navigator.vendor || window.opera
      this.webview = ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
    }

    // Fetch journey title
    const serverData = await axiosInstance.get('/fetch-journey-title/' + this.ticketUid)
    this.journeyTitle = serverData.data.publicTitle

    // Checking and activating language
    if (this.$i18n.locale !== this.$store.state.mainInfos.language) {
      console.log('i18n : switching to locale ', this.$store.state.mainInfos.language)
      this.$i18n.locale = this.$store.state.mainInfos.language
    }
  },
  methods: {
    async onPermissionsOk () {
      this.$router.push({ path: this.$route.query.redirectTo }, () => {}) // second argument means that there can be another redirection behind - See Vue-router issue #2881
    },
    moveToLanguageSelection () {
      this.$router.push({
        name: 'SelectLanguage',
        query: {
          redirectTo: this.$route.query.redirectTo
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>

  #get-permissions
    padding-top: 30px

  #selected-language
    position: fixed
    top: 10px
    right: 10px
    z-index: 101
    img
      width: 40px
      height: 27px

  #visitor-entry-loading
    progress
      margin: auto
      width: 50%
      margin-top: 150px

  h1
    font-size: 26px
    font-weight: normal
    padding-top: 50px

  .notification
    max-width: 500px
    margin: auto

  .background
    position: fixed
    top: 0
    left: 0

    background-size: 100% auto
    @media (max-aspect-ratio: 1/1)
      background-size: auto 100%

    background-position: bottom center
    background-repeat: no-repeat

    width: 100%
    height: 100%
    z-index: 2

  #visitor-entry-content
    z-index: 100
    position: relative

</style>
