<template>
  <div>
     <div v-if="gpsDisabled" id="disabled-gps-message">
      {{ $t("gps.disabled1") }}<br/>
      {{ $t("gps.disabled2") }}<br/><br/>

      <button class="button is-success" @click="startGps">
        {{ $t("gps.disabledLetsGo") }}
      </button>
    </div>
    <div v-if="closeToTarget" class="notification is-info is-light">
      {{ $t("scene.sayHi.thinkISeeYou") }}
    </div>
  </div>
</template>

<script>
import GpsSceneWithTargetMixin from '@/mixins/GpsSceneWithTarget'
export default {
  name: 'SayHi',
  mixins: [GpsSceneWithTargetMixin],
  props: ['attributes', 'number'],
  data () {
    return {
      cumulativeX: 0,
      closeToTarget: false,
      isValidated: false
    }
  },
  mounted () {
    window.addEventListener('devicemotion', this.onDeviceMotion)
  },
  unmounted () {
    window.removeEventListener('devicemotion', this.onDeviceMotion)
  },
  computed: {
    // Target, used by mixin to calculate distance
    target () {
      return this.attributes.where
    }
  },
  methods: {
    onDeviceMotion (evt) {
      if (!this.closeToTarget || this.isValidated) {
        return
      }
      const current = evt.acceleration
      const treshold = 10
      if (current.x > treshold) {
        this.cumulativeX += current.x
      }
      if (this.cumulativeX > 700) {
        this.validate()
        this.isValidated = true
      }
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    // Raised by mixin when user is close to target
    onDistanceUpdate (value) {
      console.log('distance updated', value)
      if (value > 15) {
        this.closeToTarget = false
        // Reset smartphone cumulative acceleration
        this.cumulativeX = 0
      } else {
        this.closeToTarget = true
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .notification.is-info
    margin-top: 20px
</style>
