<template>
  <div class="modal" :class="{'is-active': displayed}" id="clues-modal">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div ref="startOfClues"></div>
      <div class="clues">
        <transition-group name="list" tag="p">
          <div class="clue list-item" key="'ask-for-more-clue'" :style="{'opacity': cluesRemaining ? '1': '0'}">
            <div class="penalty-info">
              <span v-if="hasPenalty">
                {{ $t("clues.penaltyExplain") }}
              </span>
              <span v-else>
                {{ $t("clues.noPenalty") }}
              </span>
            </div>
            <button
              v-if="cluesRemaining"
              class="button clue-button is-rounded"
              :class="{'skip-button': nextClue.skip}"
              @click="askForClue()">
              <span v-if="!nextClue.skip">
                {{ $t("clues.displayNext") }}
              </span>
              <span v-if="nextClue.skip">
                {{ $t("clues.skip1") }}<br/>{{ $t("clues.skip2") }}
              </span><br/>
              <span class="next-clue-penalty" v-if="nextClue.penalty">
                <i class="far fa-clock"></i> +{{ penaltyStr(nextClue.penalty) }}
              </span>
            </button>
          </div>
          <div class="clue list-item" v-for="(clue, index) in displayedClues" :key="clue.text">
            <div
              class="notification is-default"
              :ref="'standardClue' + index.toString()"
              v-if="!clue.skip">
              {{ clue.text }}
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeModal">
    </button>
  </div>
</template>

<script>
import { formatElapsedTime } from '@/functions/time'

export default {
  name: 'CluesModal',
  props: ['displayed'],
  data () {
    return {
      waitingForClue: false
    }
  },
  watch: {
    displayed (value) {
      this.waitingForClue = false
      // When modal is displayed, scroll to top
      if (value) {
        this.scrollToTop()
      }
    },
    nbDisplayedClues (value, previous) {
      this.waitingForClue = false
      // When a new clue is avaible, scroll to top
      if (value > previous) {
        this.scrollToTop()
      }
    }
  },
  methods: {
    askForClue () {
      if (this.waitingForClue) {
        return
      }
      this.waitingForClue = true
      const nextClue = this.nextClue
      this.$store.dispatch('askForClue')
      if (nextClue.skip) {
        // Special case for "skipping" clue - do not display anything and move to next step
        this.forceValidate()
      }
    },
    closeModal () {
      this.$emit('update:displayed', false)
    },
    forceValidate () {
      this.$store.dispatch('validateItem', {
        number: this.$store.state.itemNumber
      })
      this.closeModal()
    },
    scrollToTop () {
      const elt = this.$refs.startOfClues
      setTimeout(function () {
        elt.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 100)
    },
    // From penalty value in seconds, returns a string
    penaltyStr (value) {
      if (value % 60 === 0) {
        return (value / 60).toString() + ' min.'
      }
      return formatElapsedTime(value)
    }
  },
  computed: {
    clues () {
      return this.$store.state.clues
    },
    hasPenalty () {
      for (const clue of this.$store.state.clues) {
        if (clue.penalty > 0) {
          return true
        }
      }
      return false
    },
    lastDisplayedClue () {
      return this.$store.state.lastDisplayedClue
    },
    displayedClues () {
      const res = []
      for (const [i, clue] of this.clues.entries()) {
        if (i + 1 > this.lastDisplayedClue) {
          break
        }
        if (clue.skip) {
          continue
        }
        res.push(clue)
      }
      return res.reverse()
    },
    nbDisplayedClues () {
      return this.displayedClues.length
    },
    cluesRemaining () {
      return this.lastDisplayedClue < this.clues.length
    },
    nextClue () {
      if (!this.cluesRemaining) {
        return null
      }
      return this.clues[this.lastDisplayedClue]
    }
  }
}
</script>

<style scoped lang="sass">
  .modal
    z-index: 110
    color: white

  .clues
    padding: 40px

  .notification
    color: black

  .clue
    margin-bottom: 20px
    .skip-clue
      color: black
    transition: all 1.5s ease

  .clue-button
    background-color: #99dbd9
    border: none
    display: block
    margin: auto
    padding: 10px 30px
    -webkit-tap-highlight-color: transparent
    &:focus
      box-shadow: none !important
      outline: none !important
      outline-style: none !important
      user-select: none !important
    &.skip-button
      padding: 10px 50px
      background-color: #fab741
  .next-clue-penalty
    font-weight: bold

  .penalty-info
    color: white
    margin-bottom: 15px

  .list-move
    transition: transform 0.8s ease

  .list-enter-from, .list-leave-to
    opacity: 0

</style>
