<template>
  <div>
  </div>
</template>

<script>
import isSafari from '@/functions/safari'

export default {
  name: 'ScenePlaySound',
  props: ['attributes'],
  data () {
    return {
      audio: null
    }
  },
  mounted () {
    // Play sound when mounted / displayed
    const track = this.getAudioTrack()
    track.volume = 1
    track.src = this.attributes.url
    if (this.attributes.loop) {
      track.loop = true
    }
  },
  unmounted () {
    const track = this.getAudioTrack()
    let stopMode = this.attributes.stopMode

    if (stopMode === 'decrease' && isSafari()) {
      // With Safari, volume is read-only. We can only pause the sound to stop it
      stopMode = 'immediate'
    }

    if (stopMode === 'immediate') {
      track.volume = 0
      track.pause()
    }

    const originalSrc = track.src
    function decrease () {
      console.log('decrease', track.src, originalSrc)
      if (track.src !== originalSrc) {
        return // Not the same sound
      }
      if (track.volume <= 0.1) {
        track.pause()
      } else {
        track.volume -= 0.1
        setTimeout(decrease, 1000)
      }
    }

    if (stopMode === 'decrease') {
      track.volume = 1
      decrease()
    }
  },
  methods: {
    getAudioTrack () {
      return this.$store.state.audio.tracks[this.attributes.track]
    }
  }
}
</script>
