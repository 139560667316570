<template>
  <div id="wrapper">
    <div v-if="gpsDisabled" id="disabled-gps-message">
      {{ $t("gps.disabled1") }}<br/>
      {{ $t("gps.disabled2") }}<br/><br/>

      <button class="button is-success" @click="startGps">
        {{ $t("gps.disabledLetsGo") }}
      </button>
    </div>
    <div id="distance" v-if="gpsDisabled === false && position && distanceToTarget < 10000">
      {{ distanceToTarget }}m
    </div>
    <div id="waiting-for-gps-signal" v-if="gpsDisabled === undefined && !position">
      <img src="/img/gps-signal.png"/>
      <progress class="progress is-small is-default" max="100">15%</progress>
    </div>
    <div id="close-to-destination" v-if="closeToDestination" class="notification is-info">
      <p v-if="precise">
        {{ $t("scene.map.closeToArrival") }}<br/>
        <span>{{ $t("scene.map.estimatedDistance") }} {{ distanceToTarget }}m</span>
      </p>
      <p v-if="!precise">
        {{ $t("scene.map.hereYouAre") }}
      </p>
      <button class="button is-success" @click="validate" v-if="!isValidated">
        <span v-if="precise">{{ $t("scene.map.hereIAm") }}</span>
        <span v-if="!precise">{{ $t("common.continue") }}</span>
      </button>
      <progress class="progress is-small is-default" max="100" v-if="isValidated">15%</progress>
    </div>
    <div id="map" v-if="gpsDisabled === false">
      <ol-map style="height: 100%">
        <ol-view ref="view" :center="centerCoordinates" :rotation="currentOrientation" projection="EPSG:4326" :zoom="zoom" :maxZoom="19"/>

        <ol-tile-layer ref="osmLayer" title="OSM">
          <ol-source-osm />
        </ol-tile-layer>

        <ol-vector-layer>
          <ol-source-vector>
              <ol-feature v-if="position">
                <ol-geom-point :coordinates="[position.coords.longitude, position.coords.latitude]"></ol-geom-point>
                <ol-style>
                  <ol-style-icon src="/img/gps-current-position.png" :scale="0.15"></ol-style-icon>
                </ol-style>
              </ol-feature>

              <ol-feature>
                <ol-geom-point :coordinates="[attributes.target.long, attributes.target.lat]"></ol-geom-point>
                <ol-style>
                  <ol-style-icon src="/img/gps-target.png" :scale="0.15"></ol-style-icon>
                </ol-style>
              </ol-feature>

              <ol-feature v-if="!targetOnly">
                <ol-geom-line-string :coordinates="lineCoordinates"></ol-geom-line-string>
                <ol-style>
                  <ol-style-stroke color="red" :width="2"></ol-style-stroke>
                </ol-style>
              </ol-feature>

              <ol-feature v-if="targetOnly">
                <ol-geom-line-string :coordinates="directTargetLineCoordinates"></ol-geom-line-string>
                <ol-style>
                  <ol-style-stroke color="rgba(255, 0, 0, 0.5)" :width="3"></ol-style-stroke>
                </ol-style>
              </ol-feature>

          </ol-source-vector>
        </ol-vector-layer>
      </ol-map>
    </div>
  </div>
</template>

<script>
import GpsSceneWithTargetMixin from '@/mixins/GpsSceneWithTarget'

export default {
  name: 'SceneMap',
  props: ['attributes', 'number'],
  mixins: [GpsSceneWithTargetMixin],
  data () {
    return {
      zoom: 19,
      closeToDestination: false,
      isValidated: false
    }
  },
  computed: {
    targetOnly () {
      return this.attributes.points.length === 0
    },
    precise () {
      return this.attributes.precise
    },
    centerCoordinates () {
      if (!this.attributes) {
        return []
      }
      // If we have the current position, center on it
      // Otherwise, center on target destination
      if (this.position) {
        return [this.position.coords.longitude, this.position.coords.latitude]
      }
      return [this.attributes.target.long, this.attributes.target.lat]
    },
    // Travel path coordinates
    lineCoordinates () {
      if (!this.attributes) {
        return []
      }
      const res = []
      for (const point of this.attributes.points) {
        res.push([point[1], point[0]])
      }
      res.push([this.attributes.target.long, this.attributes.target.lat])
      return res
    },
    // When there is no travel path, but only a target, draw a direct line
    // between current position and target
    directTargetLineCoordinates () {
      if (!this.position) {
        return []
      }
      return [
        [this.position.coords.longitude, this.position.coords.latitude],
        [this.attributes.target.long, this.attributes.target.lat]
      ]
    },
    // Target, used by mixin to calculate distance
    target () {
      return this.attributes.target
    }
  },
  watch: {
    closeToDestination (value) {
      if (value) {
        // Play sound when close to destination
        const track = this.$store.state.audio.tracks[2]

        track.volume = 1
        track.src = '/sound/arrivee.mp3'
      }
    }
  },
  methods: {
    // Raised by mixin when user is close to target
    onDistanceUpdate (distance) {
      if (distance > 50) {
        this.closeToDestination = false
        return
      }
      if (distance < this.attributes.immediateProximity) {
        this.closeToDestination = true
      } else if (distance < this.attributes.approximateProximity) {
        const comp = this
        setTimeout(function () {
          if (!comp.closeToDestination && comp.distanceToTarget < comp.attributes.approximateProximity) {
            // Still behind 20m within 15s. Let's validate - maybe an imprecise GPS
            comp.closeToDestination = true
          }
        }, this.attributes.approximateProximityDuration * 1000)
      }
    },
    validate () {
      if (this.isValidated) {
        return
      }
      this.$store.dispatch('validateItem', {
        number: this.number
      })
      this.isValidated = true
    }
  }
}
</script>

<style scoped lang="sass">
#wrapper
  height: 100%
#map
    width: 100%
    height: 100%
    background-color: white

#waiting-for-gps-signal
  margin-top: 40px
  img
    display: block
    width: 60px
    margin: auto
  .progress
    margin: auto
    margin-top: 5px
    width: 200px

#close-to-destination, #waiting-for-gps-signal
  position: absolute
  z-index: 2000
  width: 100%
  text-align: center

#close-to-destination
  top: 12%
  text-align: center
  padding: 0px 50px
  box-sizing: border-box
  color: black
  background-color: rgba(255, 255, 255, 0.9)
  p
    font-weight: bold
    span
      font-weight: normal
      display: block
      padding-top: 5px
  button
    margin: 10px 0px
  progress
    margin-top: 24px
    margin-bottom: 24px
    display: inline-flex

#disabled-gps-message
  padding-top: 100px

#distance
  position: absolute
  bottom: 10px
  left: 10px
  background-color: white
  border: solid 1px black
  z-index: 500
  font-size: 14px
  padding: 2px 5px
</style>
