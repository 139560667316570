<template>
  <input
    :type="type"
    :value="modelValue"
    ref="theInput"
    class="input"
    @click="selectAll"
    :placeholder="placeholder"/>
</template>

<script>
// FakeInput text is an input text, but with catching of "keydown"
// event to block it. This is done to trick Ios and avoid "shake by undo"
export default {
  name: 'FakeInputText',
  props: {
    modelValue: String,
    maxlength: Number,
    placeholder: String,
    getFocus: Boolean,
    type: {
      type: String,
      default: 'text'
    }
  },
  mounted () {
    this.$refs.theInput.addEventListener('input', this.onInput)
    this.$refs.theInput.addEventListener('keydown', this.onKeydown)
  },
  watch: {
    getFocus: function (value) {
      if (value) {
        this.$refs.theInput.focus()
        this.$refs.theInput.select()
      }
    }
  },
  methods: {
    isValidNumber (value) {
      return !isNaN(value) && !isNaN(parseFloat(value))
    },
    checkValidity () {
      if (this.$refs.theInput.validity.valid) {
        this.$emit('update:invalidMessage', false)
      } else {
        this.$emit('update:invalidMessage', this.$refs.theInput.validationMessage)
      }
    },
    onInput (e) {
      e.preventDefault()
      // Removing accents
      let value = this.$refs.theInput.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      if (this.type === 'number' && !this.isValidNumber(value)) {
        return
      }
      if (this.maxlength && value.length > this.maxlength) {
        value = value.substring(0, this.maxlength)
      }
      this.$emit('update:modelValue', value)
      this.checkValidity()
    },
    onKeydown (evt) {
      evt.preventDefault()
      if (evt.code !== 'Backspace' && evt.key.length > 1) {
        return
      }
      if (this.type === 'number' && !this.isValidNumber(evt.key) && evt.code !== 'Backspace') {
        return
      }
      this.addChar(evt.key, evt.code === 'Backspace')
    },
    addChar (key, backspace) {
      let newValue = this.modelValue
      if (backspace) {
        newValue = newValue.slice(0, -1)
      } else {
        if (newValue.length >= this.maxlength) {
          newValue = newValue.substring(this.maxlength)
        }
        // Removing accents
        key = key.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        newValue += key
      }
      this.$emit('update:modelValue', newValue)
      this.checkValidity()
    },
    selectAll () {
      this.$refs.theInput.select()
    }
  }
}
</script>

<style scoped lang="sass">
</style>
