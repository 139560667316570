<template>
  <div>
    <div id="scrollable-icon" v-if="scrollable">
      <i class="fas fa-up-down-left-right"></i>
    </div>
    <div id="floating-text" v-if="attributes.floatingText">
      {{ attributes.floatingText }}
    </div>
    <div id="wrong-answer" v-if="wrongAnswer">
      {{ $t("scene.imagetotap.nope") }}
    </div>
    <img :src="attributes.url" ref="theImg" @click="onClick"/>
  </div>
</template>

<script>
import PuzzleMixin from '@/mixins/Puzzle'

export default {
  name: 'SceneImageToTap',
  mixins: [PuzzleMixin],
  props: ['attributes', 'number'],
  computed: {
    scrollable () {
      return this.attributes.scrollable
    }
  },
  data () {
    return {
      wrongAnswer: false
    }
  },
  mounted () {
    if (this.attributes.initialScrollTo) {
      const theImg = this.$refs.theImg
      const scrollableParent = theImg.closest('.scrollable-zone')
      const x = this.attributes.initialScrollTo.x
      const y = this.attributes.initialScrollTo.y
      theImg.onload = function () {
        scrollableParent.scrollLeft = x
        scrollableParent.scrollTop = y
      }
    }
  },
  methods: {
    onClick (evt) {
      const target = this.attributes.target
      const targetDistance = Math.sqrt((evt.offsetX - target.x) ** 2 + (evt.offsetY - target.y) ** 2)
      if (targetDistance < 20) {
        // Success
        this.playVictorySound()
        this.$store.dispatch('validateItem', {
          number: this.number
        })
      } else {
        // Failure
        this.wrongAnswer = true
        const component = this
        setTimeout(function () {
          component.wrongAnswer = false
        }, 3000)
        this.playFailedSound()
      }
    }
  }
}
</script>

<style scoped lang="sass">
  #scrollable-icon
    position: absolute
    width: 100%
    text-align: center
    opacity: 0.3
    margin-top: 60px
    pointer-events: none
    .fa-up-down-left-right
      font-size: 80px
      color: #844
      pointer-events: none

  img
    overflow: scroll

  #floating-text
    position: absolute
    pointer-events: none
    width: 50%
    text-align: center
    opacity: 0.8
    padding: 5px
    color: rgba(255, 255, 255, 0.9)
    font-weight: 600
    background-color: rgba(86, 43, 170, 0.3)

  #wrong-answer
    position: absolute
    pointer-events: none
    left: 25%
    right: 25%
    top: 100px
    z-index: 10000
    text-align: center
    opacity: 0.8
    padding: 10px 30px
    color: rgba(255, 255, 255, 0.9)
    font-weight: 600
    background-color: rgba(255, 0, 0, 0.6)

</style>
