<template>
  <div>
    <img
      :src="attributes.url"
      :style="imageStyle"
      class="scene-image"
      ref="theImg"
    />
  </div>
</template>

<script>
export default {
  name: 'SceneImage',
  props: ['attributes'],
  mounted () {
    const theImg = this.$refs.theImg
    theImg.onload = function () {
      // Ugly patch
      // Scroll into view is not working properly when image is not loaded entirely
      theImg.scrollIntoView({ behavior: 'smooth' })
    }
  },
  computed: {
    imageStyle () {
      const res = {}
      if (this.$store.state.blurDisplay) {
        res.filter = 'blur(3px)'
      }

      if (this.attributes.width) {
        res.width = this.attributes.width
      }
      return res
    }
  }
}
</script>

<style scoped lang="sass">
  .scene-image
    margin: 10px 0px
</style>
