<template>
  <div>
    <figure class="image is-16by9">
      <iframe class="has-ratio" width="640" height="360" :src="youtubeUrl" frameborder="0" allowfullscreen></iframe>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'SceneYoutubeVideo',
  props: ['attributes'],
  computed: {
    youtubeUrl () {
      return this.attributes.url + '?controls=0&modestbranding=1&rel=0&autohide=1&showinfo=0'
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
