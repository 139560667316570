<template>
  <div>
    <progress
      v-if="isProcessing || videoLoading"
      class="progress is-large is-default"
      max="100">
    </progress>
    <div
      v-if="videoFailed"
      class="notification is-danger">
      {{ $t("scene.selfie.cameraFailed1") }}<br/><br/>
      <button class="button is-default" @click="forceValidate">
        {{ $t("scene.selfie.cameraFailed2") }}
      </button>
    </div>
    <div
      v-if="isWide === false"
      class="please-rotate notification is-primary">
      {{ $t("scene.selfie.pleaseRotate1") }} <strong>{{ $t("scene.selfie.pleaseRotate2") }}</strong>
    </div>
    <div
      v-if="!isProcessing && isReviewing"
      id="preview-wrapper">
      <img
        class="preview-photo"
        :src="previewData"
        :style="previewStyle"
      />
      <button
        class="button is-success"
        @click="validate">
        {{ $t("common.validate") }}
      </button>
      <button
        class="button is-danger"
        @click="retry">
        {{ $t("common.retry") }}
      </button>
      <img
        class="insertion"
        :src="image.url"
        v-for="(image, index) in attributes.images"
        :key="image.url"
        :style="imgStyles[index]"/>
    </div>
    <div
      id="video-wrapper"
      v-if="!isProcessing"
      v-show="!isReviewing"
      ref="video_wrapper">
      <div
        id="capture-btn-wrapper"
        v-if="isVideoReady"
        :style="captureBtnStyle">
        <div @click="capture()" id="capture-btn-wrapper-2">
          <i
            v-if="isWide"
            class="fas fa-record-vinyl"
            @click="capture()">
          </i>
        </div>
      </div>
      <video
        ref="video"
        id="video"
        @resize="onVideoResized"
        :style="videoStyle"
        autoplay>
      </video>
      <img
        class="insertion"
        :src="image.url"
        v-for="(image, index) in attributes.images"
        :key="image.url"
        :style="imgStyles[index]"/>
    </div>
    <canvas ref="canvas" id="canvas"/>
  </div>
</template>

<script>
export default {
  name: 'SceneSelfie',
  props: ['attributes', 'number'],
  data () {
    return {
      video: {},
      canvas: {},
      captures: [],
      captureBtnHeight: 0,
      previewData: '',
      isWide: null,
      videoLoading: false,
      videoFailed: false,
      videoStream: null,
      isProcessing: false,
      isReviewing: false,
      videoOffsets: {
        left: 0, right: 0
      },
      previewWidth: 0,
      previewHeight: 0,
      nbVideoErrors: 0
    }
  },
  mounted () {
    this.isWide = window.innerWidth > window.innerHeight
    this.video = this.$refs.video
    if (this.isWide) {
      console.log('start loading video')
      this.videoLoading = true
      setTimeout(this.startVideo, 2000)
    }
    window.addEventListener('resize', this.onResize, false)
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize)
    this.stopVideo()
  },
  computed: {
    captureBtnStyle () {
      return {
        top: (this.captureBtnHeight - 40).toString() + 'px'
      }
    },
    isVideoReady () {
      return this.captureBtnHeight > 0
    },
    videoStyle () {
      if (!this.isWide) {
        return {
          visibility: 'hidden'
        }
      }
      return {}
    },
    previewStyle () {
      return {
        width: this.previewWidth.toString() + 'px',
        height: this.previewHeight.toString() + 'px'
      }
    },
    imgStyles () {
      const res = []
      for (const image of this.attributes.images) {
        const imageStyle = {}
        if (image.location.left !== undefined) {
          imageStyle.left = (image.location.left + this.videoOffsets.left).toString() + 'px'
        }
        if (image.location.right !== undefined) {
          imageStyle.right = (image.location.right + this.videoOffsets.right).toString() + 'px'
        }
        if (image.location.top !== undefined) {
          imageStyle.top = image.location.top.toString() + 'px'
        }
        if (image.location.bottom !== undefined) {
          imageStyle.bottom = image.location.bottom.toString() + 'px'
        }
        if (image.width) {
          imageStyle.width = image.width
        }
        if (image.height) {
          imageStyle.height = image.height
        }
        if (!this.isWide || !this.isVideoReady) {
          imageStyle.visibility = 'hidden'
        }
        res.push(imageStyle)
      }
      return res
    }
  },
  methods: {
    capture () {
      console.log('capture !')
      this.processPreview()
    },
    async processPreview () {
      this.canvas = this.$refs.canvas
      const videoRatio = this.video.videoHeight / this.video.videoWidth
      this.canvas.width = 1024
      this.canvas.height = Math.round(videoRatio * this.canvas.width)
      this.canvas.getContext('2d').drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)
      this.previewData = this.canvas.toDataURL('image/jpeg', 0.92)
      this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.isReviewing = true
      this.previewWidth = this.video.videoWidth
      this.previewHeight = this.video.videoHeight
      // this.stopVideo()
    },
    async validate () {
      console.error('processing...')
      this.isProcessing = true
      this.isReviewing = false
      await this.$store.dispatch('sendPicture', {
        data: this.previewData,
        name: this.attributes.name
      })
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    forceValidate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    retry () {
      this.isReviewing = false
      this.startVideo()
    },
    stopVideo () {
      if (!this.videoStream) {
        return
      }
      this.videoStream.getTracks().forEach(function (track) {
        track.stop()
      })
      if (!this.video.srcObject) {
        return
      }
      this.video.srcObject = null
    },
    startVideo () {
      const comp = this
      this.videoFailed = false
      this.stopVideo()
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // The 3 next lines are a fixbug for iOS Safari
        this.video.setAttribute('autoplay', '')
        this.video.setAttribute('muted', '')
        this.video.setAttribute('playsinline', '')
        console.error('Ask for user media with width/height :')
        const width = Math.max(screen.width, screen.height)
        const height = Math.min(screen.width, screen.height)
        console.error(width)
        console.error(height)
        console.log('inner : ', window.innerWidth, window.innerHeight)
        navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: width, max: width },
            height: { ideal: height, max: height },
            facingMode: 'user'
          }
        }).then(stream => {
          console.log('video loaded !')
          comp.videoStream = stream
          comp.videoLoading = false
          comp.video.srcObject = stream
          comp.video.play()
        }).catch(function (err) {
          console.log('video loading error')
          console.error(err)
          comp.nbVideoErrors += 1
          if (comp.nbVideoErrors < 5) {
            console.log('retrying in 2s')
            // Retrying
            setTimeout(comp.startVideo, 2000)
          } else {
            comp.videoLoading = false
            comp.videoFailed = true
          }
        })
      }
    },
    // updateVideo () {
    //   console.log('skip update')
    //   return
    //   if (!this.video.srcObject) {
    //     return
    //   }
    //   console.error('Update video with height :')
    //   const width = Math.max(screen.width, screen.height)
    //   const height = Math.min(screen.width, screen.height)
    //   console.error(width)
    //   console.error(height)
    //   this.video.srcObject.getTracks()[0].applyConstraints(
    //     {
    //       width: { ideal: width, max: width },
    //       height: { ideal: height, max: height }
    //     }
    //   )
    // },
    onResize () {
      console.error('resize')
      this.isWide = window.innerWidth > window.innerHeight
      if (this.isWide && !this.videoLoading) {
        console.error('-> restart video')
        this.videoLoading = true
        setTimeout(this.startVideo, 2000)
      }
      // } else {
      //   this.updateVideo()
      // }
    },
    onVideoResized () {
      const comp = this
      this.$nextTick(function () {
        comp.videoOffsets = {
          left: comp.video.offsetLeft,
          right: comp.$refs.video_wrapper.offsetWidth - (comp.video.offsetLeft + comp.video.offsetWidth)
        }
        comp.updatePhotoBtnHeight()
      })
    },
    updatePhotoBtnHeight () {
      this.captureBtnHeight = this.video.videoHeight / 2
    }
  }
}
</script>

<style scoped lang="sass">
  .progress
    margin: auto
    margin-top: 100px
    margin-bottom: 100px
    max-width: 300px
  #canvas
    display: none
  #video-wrapper
    position: relative
    video
      max-height: 100%
      max-width: 100%
      z-index: 120
      -webkit-transform: scaleX(-1)
      transform: scaleX(-1)

    #capture-btn-wrapper
      position: fixed
      z-index: 200
      width: 100%
      text-align: center
      #capture-btn-wrapper-2
        width: 80px
        margin: auto
      .fa-record-vinyl
        font-size: 80px

  img.insertion
    position: fixed
    z-index: 150
    user-select: none

  .please-rotate
    margin-top: 150px

  #preview-wrapper
    width: 100%
    height: 100%
    position: fixed
    img.preview-photo
      -webkit-transform: scaleX(-1)
      transform: scaleX(-1)
      user-select: none
    .button
      z-index: 200
      right: 20px
      position: fixed
    .button.is-success
      top: 80px
    .button.is-danger
      top: 20px

</style>
